/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_linkedin-rond': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<circle pid="0" opacity=".3" cx="18" cy="18" r="18" _fill="#fff"/><g clip-path="url(#clip0)" _fill="#fff"><path pid="1" d="M11.983 12.197a2.188 2.188 0 100-4.376 2.188 2.188 0 000 4.376zM16.237 13.855v12.139h3.769V19.99c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.81 1.961 3.218v5.904H28v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66h-3.618zm-6.142 0h3.775v12.139h-3.775v-12.14z"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" transform="translate(6 6)" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
