<template>
  <div class="the-header">
    <div class="begin">
      <div
        class="web-header flex px-32 py-4 items-center justify-between"
        :class="{ 'has-shadow': isSearch }"
      >
        <div class="flex-icon">
          <svg-icon
            name="ic_rema-green"
            original
            class="w-40 cursor-pointer"
          />
        </div>
        <div class="flex-one  option-button-section flex items-center">
          <div class="flex-one mr-4">
            <div class="flex justify-center p-3 m-1 rounded text-xl text-white font-medium cursor-pointer login-button">
              <a href="https://www.remaapp.com" target="_blank">
                {{ $t('headerButton') }}
              </a>
            </div>
          </div>
          <div class="flex-one mr-4">
            <a href="https://www.linkedin.com/company/rema-r%C3%A9seau-d-echange-entre-m%C3%A9decins-d-afrique/" target="_blank">
              <svg-icon
                name="ic_linkedin"
                original
                class="w-12 cursor-pointer"
              />
            </a>
          </div>
          <div class="flex-one mr-4">
            <a href="https://web.facebook.com/REMA-1394986370587734/?ref=br_rs" target="_blank">
              <svg-icon
                name="ic_facebook"
                original
                class="w-12 cursor-pointer"
              />
            </a>
          </div>
          <div class="flex-one">
            <svg-icon
              name="ic_twitter"
              original
              class="w-12 cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div class="mobile-header h-24">
        <div class="flex-container p-4 flex">
          <div class="logo-mobile">
            <svg-icon
              name="ic_rema-green"
              original
              class="w-40 cursor-pointer"
            />
          </div>
          <div class="flex-one flex-grow items-center flex justify-end ">
            <div class="burger" v-if="!showMobileMenu" @click="showMobileMenu = !showMobileMenu">
              <svg-icon
                name="ic_menu"
                original
                class="w-12"
              />
            </div>
            <div class="close-burger" v-if="showMobileMenu" @click="showMobileMenu = !showMobileMenu">
              <svg-icon
                name="ic_close-menu"
                original
                class="w-12"
              />
            </div>
          </div>
        </div>
        <div class=" w-full mobile-menu-items shadow-lg p-4" :class="{ 'mobile-menu-items-active': showMobileMenu}">
          <div class=" w-full">
            <div class="flex-one">
              <div class="flex justify-center p-3 m-1 rounded-lg text-xl text-white font-medium cursor-pointer login-button">
                <a href="https://www.remaapp.com" target="_blank">
                  {{ $t('headerButton') }}
                </a>
              </div>
            </div><br>
            <div class="flex-one flex m-auto">
              <div class="flex-one mr-4 m-auto">
                <a href="https://www.linkedin.com/company/rema-r%C3%A9seau-d-echange-entre-m%C3%A9decins-d-afrique/" target="_blank">
                  <svg-icon
                    name="ic_linkedin"
                    original
                    class="w-12 cursor-pointer"
                  />
                </a>
              </div>
              <div class="flex-one mr-4 m-auto">
                <a href="https://web.facebook.com/REMA-1394986370587734/?ref=br_rs" target="_blank">
                  <svg-icon
                    name="ic_facebook"
                    original
                    class="w-12 cursor-pointer"
                  />
                </a>
              </div>
              <div class="flex-one m-auto">
                <svg-icon
                  name="ic_twitter"
                  original
                  class="w-12 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'the-header',
  data () {
    return {
      showMobileMenu: false
    }
  },
  props: {
    isSearch: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .the-header {
    z-index: 9999;
  }
  .has-shadow {
    box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24);
  }
  .login-button {
    background-color: $rema-green;
  }
  .mobile-menu-items {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;
  }
  .mobile-menu-items-active {
    transform: scaleY(1);
    background-color: white;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .mobile-header {
    display: none;
  }
  a {
    color: $white;
  }
  @media only screen and (max-width: 600px) {
    .mobile-header {
      display: block;
      z-index: 10000;
      background-color: white;
    }
    .web-header {
      display: none;
    }
    .search-section {
      display: none;
    }
  }
</style>
