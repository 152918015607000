/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_linkedin': {
    width: 37,
    height: 39,
    viewBox: '0 0 37 39',
    data: '<path pid="0" d="M.765 12.65a8.853 8.853 0 016.998-8.656L25.468.2C30.98-.981 36.176 3.22 36.176 8.856V26.16a8.853 8.853 0 01-6.998 8.657L11.472 38.61C5.963 39.79.765 35.59.765 29.954V12.65z" _fill="#F6F7F8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 12.892a1.225 1.225 0 011.225-1.226h12.215a1.224 1.224 0 011.226 1.226v12.215a1.224 1.224 0 01-1.226 1.226H11.892a1.224 1.224 0 01-1.225-1.226V12.892zm5.805 4.367h1.986v.997c.287-.573 1.02-1.09 2.122-1.09 2.113 0 2.613 1.142 2.613 3.238v3.881h-2.138v-3.404c0-1.193-.286-1.866-1.014-1.866-1.01 0-1.43.726-1.43 1.866v3.404h-2.139V17.26zm-3.667 6.935h2.139v-7.027h-2.139v7.026zm2.445-9.32a1.375 1.375 0 11-2.75.06 1.375 1.375 0 012.75-.06z" _fill="#16B853"/>'
  }
})
