/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_facebook': {
    width: 36,
    height: 43,
    viewBox: '0 0 36 43',
    data: '<path pid="0" d="M.294 14.745A8.853 8.853 0 017.292 6.09l17.706-3.794c5.51-1.181 10.708 3.02 10.708 8.656v17.304a8.853 8.853 0 01-6.998 8.656l-17.706 3.794C5.49 41.886.294 37.685.294 32.05V14.745z" _fill="#F6F7F8"/><path pid="1" d="M13.183 12.647a2.765 2.765 0 00-2.771 2.771v9.634a2.765 2.765 0 002.77 2.771h5.223V21.89h-1.57v-2.136h1.57V17.93c0-1.433.926-2.75 3.062-2.75.864 0 1.504.083 1.504.083l-.05 1.995s-.652-.006-1.364-.006c-.77 0-.893.354-.893.944v1.56h2.318l-.101 2.135h-2.218v5.933h2.154a2.765 2.765 0 002.771-2.77v-9.635a2.765 2.765 0 00-2.77-2.771h-9.635z" _fill="#16B853"/>'
  }
})
