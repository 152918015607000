/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_twitter': {
    width: 37,
    height: 43,
    viewBox: '0 0 37 43',
    data: '<path pid="0" d="M.823 14.745A8.853 8.853 0 017.821 6.09l17.706-3.794c5.511-1.181 10.708 3.02 10.708 8.656v17.304a8.853 8.853 0 01-6.998 8.656l-17.706 3.794C6.021 41.886.823 37.685.823 32.05V14.745z" _fill="#F6F7F8"/><path pid="1" d="M24.492 12.647H12.567c-.898 0-1.626.728-1.626 1.626v11.924c0 .898.728 1.626 1.626 1.626h11.925c.897 0 1.626-.728 1.626-1.626V14.273c0-.898-.729-1.626-1.626-1.626zm-1.657 5.38c.007.094.007.193.007.287 0 2.938-2.236 6.322-6.322 6.322a6.293 6.293 0 01-3.41-.996c.179.02.351.027.535.027 1.04 0 1.995-.353 2.757-.949a2.225 2.225 0 01-2.077-1.541c.343.05.65.05 1.003-.04a2.222 2.222 0 01-1.778-2.182v-.027c.294.166.64.267 1.002.28a2.217 2.217 0 01-.989-1.849c0-.413.109-.793.302-1.121a6.308 6.308 0 004.58 2.324c-.315-1.508.813-2.73 2.168-2.73.64 0 1.216.267 1.622.7a4.355 4.355 0 001.41-.535 2.216 2.216 0 01-.976 1.223 4.42 4.42 0 001.28-.345c-.3.444-.68.836-1.114 1.151z" _fill="#16B853"/>'
  }
})
