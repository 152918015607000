<template>
  <div id="app">
    <the-header/>
    <router-view/>
    <the-footer/>
  </div>
</template>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
<script>
import TheFooter from './components/the-footer'
import TheHeader from './components/the-header'
export default {
  components: { TheHeader, TheFooter }
}
</script>
