/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_close-menu': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M17.88 16l5.733-5.72a1.339 1.339 0 00-1.893-1.894L16 14.12l-5.72-5.734a1.339 1.339 0 00-1.893 1.894L14.12 16l-5.733 5.72a1.333 1.333 0 000 1.893 1.333 1.333 0 001.893 0L16 17.88l5.72 5.733a1.332 1.332 0 001.893 0 1.332 1.332 0 000-1.893L17.88 16z" _fill="#16B853"/>'
  }
})
