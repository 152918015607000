/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_globe': {
    width: 73,
    height: 73,
    viewBox: '0 0 73 73',
    data: '<circle pid="0" opacity=".2" cx="36.5" cy="36.5" r="36.5" _fill="#fff"/><path pid="1" d="M43.5 24.5a7.5 7.5 0 10-15 0 7.5 7.5 0 0015 0zm-9.25 11.336c-4.636-2.838-12.144-3.617-15.922-3.832-1.27-.072-2.328.895-2.328 2.116v17.407c0 1.119.905 2.053 2.07 2.113 3.41.179 10.311.834 15.08 3.236.733.37 1.6-.133 1.6-.927V36.731c0-.365-.18-.699-.5-.895zm19.422-3.832c-3.777.214-11.286.994-15.92 3.832-.32.196-.501.544-.501.909v19.202c0 .796.87 1.299 1.605.93 4.768-2.4 11.665-3.056 15.075-3.235 1.164-.06 2.07-.994 2.07-2.114V34.12c-.001-1.22-1.06-2.188-2.33-2.116z" _fill="#fff"/>'
  }
})
