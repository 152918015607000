/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin': {
    width: 16,
    height: 16,
    viewBox: '0 0 376 376',
    data: '<path pid="0" d="M316 136c0 23.36-6.24 45.2-17.12 64L188 368 77.12 200C66.24 181.2 60 159.36 60 136 60 65.28 117.28 8 188 8s128 57.28 128 128zm-64 0c0-35.36-28.64-64-64-64s-64 28.64-64 64 28.64 64 64 64 64-28.64 64-64z" _fill="#cce4ff"/><g _fill="#007aff"><path pid="1" d="M70.44 204.408l110.88 168c1.488 2.24 3.992 3.592 6.68 3.592s5.192-1.352 6.68-3.592l111.128-168.4C317.712 183.432 324 159.912 324 136 324 61.008 262.992 0 188 0S52 61.008 52 136c0 23.912 6.288 47.432 18.192 68 .08.144.16.28.248.408zM188 16c66.168 0 120 53.832 120 120 0 21.104-5.544 41.848-15.8 59.592L188 353.48 83.928 195.8C73.504 177.704 68 157.032 68 136c0-66.168 53.832-120 120-120z"/><path pid="2" d="M188 208c39.704 0 72-32.304 72-72s-32.296-72-72-72-72 32.304-72 72 32.296 72 72 72zm0-128c30.872 0 56 25.12 56 56s-25.128 56-56 56-56-25.12-56-56 25.128-56 56-56z"/></g>'
  }
})
