/* eslint-disable */
require('./ic_appstore-green')
require('./ic_appstore')
require('./ic_close-menu')
require('./ic_facebook-rond')
require('./ic_facebook')
require('./ic_globe')
require('./ic_linkedin-rond')
require('./ic_linkedin')
require('./ic_menu')
require('./ic_playstore-green')
require('./ic_playstore')
require('./ic_rema-green')
require('./ic_rema-white')
require('./ic_twitter-rond')
require('./ic_twitter')
require('./pin')
