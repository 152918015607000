<template>
  <div class="the-footer">
    <div class="begin px-32 py-12 text-center text-white">
      <div class="logo-rema">
        <svg-icon
          name="ic_rema-white"
          original
          class="w-32 h-24 m-auto"
        />
      </div>
      <div class="message-text font-medium">
        {{ $t('messageFooter') }}
      </div><br>
      <div class="portal">
        {{ $t('africaPortal') }}
      </div>
      <div class="icones mt-16">
        <div class="store flex items-center m-auto">
          <div class="icon-playstore m-auto w-40 mr-4 cursor-pointer">
            <a href="https://play.google.com/store/apps/details?id=com.craftman.rema&hl=fr" target="_blank">
              <svg-icon
                name="ic_playstore-green"
                original
                class="w-40"
              />
            </a>
          </div>
          <div class="icon-appstore m-auto w-40 cursor-pointer">
            <a href="https://apps.apple.com/us/app/rema/id1540511460" target="_blank">
              <svg-icon
                name="ic_appstore-green"
                original
                class="w-40"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="social mt-10">
        <div class="medias flex items-center m-auto">
          <div class="icon-facebook m-auto w-10 mr-4 cursor-pointer">
            <a href="https://web.facebook.com/REMA-1394986370587734/?ref=br_rs" target="_blank">
              <svg-icon
                name="ic_facebook-rond"
                original
                class="w-10"
              />
            </a>
          </div>
          <div class="icon-twitter m-auto w-10 mr-4 cursor-pointer">
            <svg-icon
              name="ic_twitter-rond"
              original
              class="w-10"
            />
          </div>
          <div class="icon-linkedin m-auto w-10 cursor-pointer">
            <a href="https://www.linkedin.com/company/rema-r%C3%A9seau-d-echange-entre-m%C3%A9decins-d-afrique/" target="_blank">
              <svg-icon
                name="ic_linkedin-rond"
                original
                class="w-10"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'the-footer'
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .the-footer {
    background-color: $rema-green;
  }
  .store, .medias {
    width: max-content;
  }
  @media only screen and (max-width: 600px) {
    .begin {
      padding-left: 0;
      padding-right: 0;
    }
  }
</style>
