/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_twitter-rond': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<circle pid="0" opacity=".3" cx="18" cy="18" r="18" _fill="#fff"/><path pid="1" d="M28 11.929a8.216 8.216 0 01-2.356.645 4.113 4.113 0 001.804-2.27 8.194 8.194 0 01-2.606.995 4.104 4.104 0 00-6.992 3.743 11.652 11.652 0 01-8.457-4.288 4.104 4.104 0 001.27 5.479 4.094 4.094 0 01-1.859-.513v.053a4.105 4.105 0 003.292 4.023 4.132 4.132 0 01-1.853.07 4.106 4.106 0 003.833 2.85 8.234 8.234 0 01-5.097 1.756A8.35 8.35 0 018 24.415a11.604 11.604 0 006.29 1.844c7.548 0 11.674-6.252 11.674-11.675 0-.176-.004-.354-.012-.53a8.341 8.341 0 002.046-2.123L28 11.93z" _fill="#fff"/>'
  }
})
