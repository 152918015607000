/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_menu': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M29.333 7.994c0-.734-.594-1.327-1.326-1.327h-10.68a1.327 1.327 0 100 2.653h10.68a1.327 1.327 0 001.326-1.326zM29.333 16c0-.733-.594-1.326-1.326-1.326H3.993a1.327 1.327 0 000 2.653h24.014A1.327 1.327 0 0029.333 16zM28.007 22.68a1.327 1.327 0 110 2.654H11.993a1.327 1.327 0 010-2.653h16.014z" _fill="#16B853"/>'
  }
})
