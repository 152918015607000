/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_facebook-rond': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<circle pid="0" opacity=".3" cx="18" cy="18" r="18" _fill="#fff"/><path pid="1" d="M19.397 26.997v-8.196h2.765l.411-3.209h-3.176v-2.044c0-.926.258-1.56 1.587-1.56h1.684v-2.86c-.82-.089-1.643-.131-2.467-.128-2.444 0-4.122 1.492-4.122 4.231v2.355h-2.747v3.21h2.753v8.201h3.312z" _fill="#fff"/>'
  }
})
